import * as components from './components'

// WDT namespace for global configs
const WDT = window.WDT || {
    vars: {},
    methods: {
        initializeComponents: () => {
            const componentNodes = Array.from(document.querySelectorAll('[data-component]')) || []

            componentNodes.forEach(node => {
                const componentName = node.getAttribute('data-component')

                if(node.getAttribute('data-initialized')) return

                let props = node.getAttribute('data-props')
                if (props) props = JSON.parse(props)

                /* eslint-disable no-new */
                new components[componentName](node, props)

                node.setAttribute('data-initialized', 'true')
            })

            // Initialize fs lightbox
            if (typeof FsLightbox !== 'undefined') {
                refreshFsLightbox();
            }
        },
        loadLazyHtml: () => {
            const lazyHtmlNodes = Array.from(document.querySelectorAll('lazy[data-lazy-id]')) || []

            lazyHtmlNodes.forEach(node => {
                if(node.innerHTML !== '') {
                    WDT.methods.initializeComponents()
                    return
                }

                let url = window.location.href;
                // remove query string
                url = url.split('?')[0];
                // remove hash
                url = url.split('#')[0];

                let parts = url.split('/');
                let lastSegment = parts.pop() || parts.pop();
                let base = parts.join('/');

                // if count of slashes in url is 3 or less, then it's a root page
                if (url.split('/').length <= 4) {
                    lastSegment = lastSegment + '/index';
                }

                let html = lastSegment + '.' + node.getAttribute('data-lazy-id');
                let path = base + '/' + html;

                if(node.getAttribute('data-lazy-id') === 'footer' || node.getAttribute('data-lazy-id') === 'navigation') {
                    // host + locale + lazy-id
                    path = '/' + node.getAttribute('data-lazy-id');
                }


                // check for valid 200 response
                fetch(path)
                    .then(response => {
                        if (response.status !== 200) {
                            throw new Error('Invalid response');
                        }
                        return response.text();
                    })
                    .then(data => {
                        // replace the lazy element with the fetched html - no innerHTML
                        node.outerHTML = data;

                        setTimeout(() => {
                            WDT.methods.initializeComponents()
                            window.observer.observe()
                        }, 100)
                        WDT.methods.runLazyScripts()
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            })
        },
        // lazily included html may contain scripts, which are not executed by
        // innerHTML. by creating a new script element and copying the content,
        // the script gets executed.
        // @link https://stackoverflow.com/a/69190644
        runLazyScripts: () => {
            // get all script elements with the data-lazy-script attribute
            const scriptElements = document.querySelectorAll("script[data-lazy-script]");

            Array.from(scriptElements).forEach((scriptElement) => {
                const clonedElement = document.createElement("script");

                // copy the attributes
                Array.from(scriptElement.attributes).forEach((attribute) => {
                    // ignore the data-lazy-script attribute so they do not get
                    // parsed again
                    if (attribute.name === "data-lazy-script") {
                        return;
                    }
                    clonedElement.setAttribute(attribute.name, attribute.value);
                });

                // copy the content
                clonedElement.text = scriptElement.text;

                // replace the script element
                scriptElement.parentNode.replaceChild(clonedElement, scriptElement);
            });
        }
    },
}

// Minimum User Interaction (MUI)
window.mui = function () {
    let fired = false;
    const kickTrigger = function () {
        if(fired) return;

        fired = true;

        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[1], // after CookieYes Custom Consent
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-MX45SJV');

        WDT.methods.loadLazyHtml();

        // trigger muiTriggered event
        document.dispatchEvent(new Event('muiTriggered'));
    };

    document.addEventListener('focus', kickTrigger, {once: true, passive: true});
    document.addEventListener('mousemove', kickTrigger, {once: true, passive: true});
    document.addEventListener('scroll', kickTrigger, {once: true, passive: true});
    document.addEventListener('touchstart', kickTrigger, {once: true, passive: true});
}

// Initialize MUI
window.mui();